body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #EEEEEE;
}

* {
    font-family: 'Inter', sans-serif;
}

ul {
    list-style-type: none;
}